<template>
  <ion-page>
    <page-header title="Debug"/>
    <ion-content :fullscreen="true">
      <ion-button @click="resetOrderData">Reset order</ion-button>
      <pre>{{ authModule.loggedUserId }}</pre>
      <pre>{{ userAuth }}</pre>
      <pre>{{ customerData }}</pre>
      <pre>{{ orderObject }}</pre>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonButton } from '@ionic/vue';
import PageHeader from '@/components/PageStructure/PageHeader.vue';
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import cartModule from '@/store/modules/cart';
import router from "@/router";
import authModule from '@/store/modules/auth';

export default defineComponent({
  name: 'Debug',
  components: {PageHeader, IonPage, IonContent, IonButton},
  computed: {
    ...mapGetters(['isAuthenticated', 'userId', 'userAuth', 'customerData', 'orderObject']),
  },
  setup() {
    function resetOrderData() {
      cartModule.resetOrderData()
      router.push({name: 'Menu'});
    }

    return {
      resetOrderData,
      authModule,
    }
  }
})
</script>
